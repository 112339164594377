<template>
  <div class="space-y-5">
    <div class="relative flex items-start">
      <div class="flex items-center h-5">
        <input
            v-if="trueLabel || falseLabel"
            v-model="model"
            @change="handleChange"
            @focus="focus = true"
            @blur="focus = false"
            :true-value="trueLabel"
            :false-value="falseLabel"
            :id="id"
            :name="name"
            :disabled="isDisabled"
            type="checkbox"
            class="h-4 w-4 border-gray-300 rounded dark:bg-darkgray-400 dark:border-darkgray-400 focus:outline-none ring-0"
            :class="[colorClass,formClass,disabledClass]"
        />
        <input
            v-else
            v-model="model"
            @change="handleChange"
            @focus="focus = true"
            @blur="focus = false"
            :value="label"
            :id="id"
            :name="name"
            :disabled="isDisabled"
            type="checkbox"
            class="h-4 w-4 border-gray-300 rounded dark:bg-darkgray-400 dark:border-darkgray-400 focus:outline-none ring-0"
            :class="[colorClass,formClass, disabledClass]"
        />
      </div>
      <div v-if="$slots.default" class="ml-3 text-sm">
        <label :for="id" :class="[textColorClass, fontClass, 'select-none']">
          <slot></slot>
        </label>
      </div>
    </div>
    <p class="text-xs font-medium text-red-600" v-if="error">{{ error }}</p>
  </div>
</template>
<script setup>


import {useId} from "#app";

const props = defineProps({
  label: {
    type: [String, Boolean, Number, Object],
  },
  color: String,
  form: '',
  textColor: '',
  modelValue: {
    type: [Boolean, Number, String, Array],
    default: () => undefined,
  },
  trueLabel: {
    type: [String, Number],
    default: undefined,
  },
  falseLabel: {
    type: [String, Number],
    default: undefined,
  },
  indeterminate: Boolean,
  disabled: Boolean,
  checked: Boolean,
  name: {
    type: String,
    default: undefined,
  },
  size: String,
  id: {
    type: String,
    default: null,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  font: {
    type: String,
    default: 'font-normal'
  },
  error: {
    type: String,
    default: "",
  },
})

const emit = defineEmits(['update:modelValue', 'change'])

defineOptions({
  name: "Checkbox",
})

const selfModel = ref(false);
const isLimitExceeded = ref(false);
const isGroup = ref(false)

function addToStore() {
  if (Array.isArray(model.value) && !model.value.includes(props.label)) {
    model.value.push(props.label)
  } else {
    model.value = props.trueLabel || true
  }
}

const model = computed({
  get() {
    return isGroup.value ? store.value : props.modelValue ?? selfModel.value
  },

  set(val) {
    // if (isGroup.value && Array.isArray(val)) {
    //     isLimitExceeded.value = false
    //
    //     if (
    //         checkboxGroup.min !== undefined &&
    //         val.length < checkboxGroup.min.value
    //     ) {
    //         isLimitExceeded.value = true
    //     }
    //     if (
    //         checkboxGroup.max !== undefined &&
    //         val.length > checkboxGroup.max.value
    //     ) {
    //         isLimitExceeded.value = true
    //     }
    //
    //     isLimitExceeded.value === false && checkboxGroup?.changeEvent?.(val)
    // } else {
    emit('update:modelValue', val)
    selfModel.value = val;
    // }
  },
})

const isDisabled = computed(() => {
  return props.disabled;
})

props.checked && addToStore()
const focus = ref(false)

function handleChange(e) {
  if (isLimitExceeded.value) return
  const target = e.target
  const value = target.checked
      ? props.trueLabel ?? true
      : props.falseLabel ?? false

  emit('change', value, e)
}

const isChecked = computed(() => {
  const value = model.value
  if (value === '[object Boolean]') {
    return value
  } else if (Array.isArray(value)) {
    return value.includes(props.label)
  } else if (value !== null && value !== undefined) {
    return value === props.trueLabel
  } else {
    return !!value
  }
})

const colorClass = computed(() => {
  if (props.color === 'success') {
    return 'input-success'
  } else if (props.color === 'primary') {
    return 'input-primary'
  } else if (props.color === 'secondary') {
    return 'input-secondary'
  } else if (props.color === 'danger') {
    return 'input-danger'
  } else if (props.color === 'black') {
    return 'input-black'
  } else {
    return 'input-success'
  }
})

const fontClass = computed(() => {
  if (props.font === 'bold') {
    return 'font-bold'
  } else if (props.font === 'semibold') {
    return 'font-semibold'
  } else {
    return 'font-normal'
  }
})

const formClass = computed(() => {
  if (props.form === 'rounded') {
    return 'rounded-full'
  }
})

const textColorClass = computed(() => {
  if (props.textColor === 'success') {
    return 'text-success-500'
  } else if (props.textColor === 'primary') {
    return 'text-primary'
  } else if (props.textColor === 'secondary') {
    return 'text-secondary'
  } else if (props.textColor === 'danger') {
    return 'text-danger'
  } else if (props.textColor === 'gray') {
    return 'text-gray-500'
  } else if (props.textColor === 'darkgray') {
    return 'text-darkgray'
  } else if (props.textColor === 'black') {
    return 'text-black'
  } else if (props.textColor === 'lightblue') {
    return 'text-lightblue'
  } else {
    return 'text-primary'
  }
})

const disabledClass = computed(() => {
  if (props.isDisabled) {
    return 'bg-gray-300 cursor-not-allowed'
  } else {
    return null;
  }
})

</script>
